import React, { Component } from 'react';
import AirDrop from '../../components/airdrop';

export default class WhiteListPage extends Component {
  render() {
    return (
      <AirDrop/>
    );
  }
}
